@tailwind base;
@tailwind components;
@tailwind utilities;

body::-webkit-scrollbar {
  display: none;
}

.movie-list::-webkit-scrollbar {
  display: none;
}

.movie-list {
  overflow-y: hidden;
  scrollbar-width: none;
}

.scrollable {
  overflow-x: auto;
  white-space: nowrap;
}
